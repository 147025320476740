exports.components = {
  "component---src-pages-404-index-en-js": () => import("./../../../src/pages/404/index.en.js" /* webpackChunkName: "component---src-pages-404-index-en-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-404-index-pt-js": () => import("./../../../src/pages/404/index.pt.js" /* webpackChunkName: "component---src-pages-404-index-pt-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-js": () => import("./../../../src/pages/index.pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-privacy-policy-index-en-js": () => import("./../../../src/pages/privacy-policy/index.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-en-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-privacy-policy-index-pt-js": () => import("./../../../src/pages/privacy-policy/index.pt.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-pt-js" */)
}

